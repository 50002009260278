import React, { useContext } from 'react';
import classes from './Button.module.scss';
import { PopupContext } from '../../../App';

const Button = ({ children, secondary, dark, paddingSm, event, customClass = '', href }) => {
  const { setFormPopupVisible } = useContext(PopupContext);

  const openForm = () => setFormPopupVisible(true);

  const handleClick = () => {
    if (event) {
      return event();
    }

    return openForm();
  };

  if (href) {
    return (
      <a 
        className={`${classes.button} ${secondary ? classes.secondary : ''} ${paddingSm ? classes.paddingSm : ''} ${dark ? classes.dark : ''} ${customClass} no-select`}
        href={href}
        target="_blank"
      >
        <span>{children}</span>
      </a>
    );  
  }

  return (
    <div 
      className={`${classes.button} ${secondary ? classes.secondary : ''} ${paddingSm ? classes.paddingSm : ''} ${dark ? classes.dark : ''} ${customClass} no-select`}
      onClick={handleClick}
    >
      <span>{children}</span>
    </div>
  );
};

export default Button;
