import logo from '../assets/img/logo.svg';
import img_01 from '../assets/img/HomePage/Banner/bg.avif';
import img_02 from '../assets/img/HomePage/Banner/image_01.webp';
import img_03 from '../assets/img/HomePage/Banner/image_02.webp';

export const images = [
    logo,
    img_01,
    img_02,
    img_03,
];
