import $ from 'jquery';
import './App.scss';
import { createContext, useEffect, useState, Suspense, lazy } from "react";
import { images } from "./utils/imagesToLoad";
import FormPopup from './components/GlobalComponents/FormPopup/FormPopup';

export const PopupContext = createContext(null);

const HomePage = lazy(() => import('./pages/HomePage'));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [formPopupVisible, setFormPopupVisible] = useState(false);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PopupContext.Provider
        value={{
          formPopupVisible,
          setFormPopupVisible,
        }}
      >
        <FormPopup visible={formPopupVisible} setVisible={setFormPopupVisible} />
        <HomePage />
      </PopupContext.Provider>
    </Suspense>
  );
};

export default App;
