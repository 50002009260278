import $ from 'jquery';
import React, { useEffect } from "react";
import classes from './FormPopup.module.scss';
import Form from '../Form/Form';
import close_svg from '../../../assets/img/Form/close.svg';

const FormPopup = ({ visible, setVisible }) => {
    useEffect(() => {
        visible
            ? $('body').addClass("ovf-hidden ovf-padding")
            : $('body').removeClass("ovf-hidden ovf-padding");
    }, [visible]);

    return (
        <div className={`${classes.popupWrap} ${visible ? classes.visible : ''}`}>
            <div
                className={classes.closeZone}
                onClick={() => setVisible(false)}
            ></div>
            <div className={`container ${classes.container}`}>
                <div className={classes.popup}>
                    <div
                        onClick={() => setVisible(false)}
                        className={classes.closeIcon}
                    >
                        <img src={close_svg} alt='' />
                    </div>
                    <Form visible={visible} setVisible={setVisible} />
                </div>
            </div>
        </div>
    );
};

export default FormPopup;
