import React from "react";
import classes from './Input.module.scss';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Phone = ({
    setValue,
    valid = true,
}) => {
    return (
        <div>
            <div className={classes.inputField}>
                <PhoneInput
                    country={"ua"}
                    preferredCountries={["ua"]}
                    onChange={(phone) => setValue(phone)}
                    className={`${classes.phone}`}
                    containerClass={classes.containerClass}
                    inputClass={`${classes.inputClass} ${!valid && classes.error} input__phone`}
                    buttonClass={classes.buttonClass}
                    dropdownClass={classes.dropdownClass}
                    searchClass={classes.searchClass}
                />
            </div>
        </div>
    );
};

export default Phone;
